// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ergaleia-katharismou-js": () => import("./../../../src/pages/ergaleia-katharismou.js" /* webpackChunkName: "component---src-pages-ergaleia-katharismou-js" */),
  "component---src-pages-me-to-logotypo-sas-js": () => import("./../../../src/pages/me-to-logotypo-sas.js" /* webpackChunkName: "component---src-pages-me-to-logotypo-sas-js" */),
  "component---src-templates-about-us-template-js": () => import("./../../../src/templates/AboutUsTemplate.js" /* webpackChunkName: "component---src-templates-about-us-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/ContactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-general-template-js": () => import("./../../../src/templates/GeneralTemplate.js" /* webpackChunkName: "component---src-templates-general-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/HomeTemplate.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/ProductTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-serving-template-js": () => import("./../../../src/templates/ServingTemplate.js" /* webpackChunkName: "component---src-templates-serving-template-js" */)
}

